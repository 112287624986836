<!--支撑与研发平台-->
<template>
  <div class="box">
    <div class="title  wow animate__backInDown">支撑与研发平台</div>
    <div class="title2  wow animate__backInDown">Support and R & D platform</div>
    <div class="line  wow animate__backInDown"></div>
    <div class="content wow bounceInRight">
      <div v-for="(item, index) in list" :key="index" @click="move(item.pid)" class="list_style  wow bounceInRight">
        <!-- <div class="line2"></div> -->
        <div class="img">
          <img :src="item.pathUpload" alt="">
        </div>
        <div class="serial_num">0{{ index + 1 }}</div>
        <div class="title3">{{ item.title }}</div>
        <div class="word">{{ item.other }}</div>
        <div class="add1">
          <img src="../../assets/home/add2.png" width="100%" height="100%" />
        </div>
        <div class="add2">
          <img src="../../assets/home/add.png" width="100%" height="100%" />
        </div>
      </div>
    </div>
    <!-- <div class="bom">
      <div class="left">友情链接 <i class="el-icon-caret-right"></i></div>
      <div class="wrapper">
        <div v-for="(item, index) in linkList" :key="index" class="waf">
          <div>
            <a target="_blank" :href="item.url">{{ item.name }}</a>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    linkList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      option: 0,
    };
  },
  mounted(){
    new this.$wow.WOW().init()
  },
  methods: {
    move(e) {
      this.$emit("click", {pid:e,option:3});
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  padding: .0625rem;
  position: relative;
  overflow: hidden;
  .title {
    font-size: 2.25rem;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #356ceb;
    text-align: center;
    margin-top: 6rem;
  }
  .title2 {
    font-size: 1.5rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #5c5c5c;
    margin-top: 1.5rem;
    text-align: center;
  }
  .line {
    width: 1.875rem;
    height: .3125rem;
    background: #356ceb;
    margin: 0 auto;
    margin-top: 1.75rem;
    margin-bottom: 5.1875rem;
  }
  .content {
    .img{
      width: 100%;
      height: 12.5rem;
      img{
        width: 100%;
        height: 100%;
      }
      // background-color: #333333;
    }
    cursor: pointer;
    display: flex;
    justify-content: center;
    .list_style:hover {
      box-shadow: 0px 0px .9375rem 1px rgba(34, 39, 46, 0.3);
      transform: translateY(-20px);
      transition: all 0.3s; 
      .line2 {
        background: #356ceb;
      }
      .serial_num {
        color: #356ceb;
        transform: translateY(-20px);
      transition: all 0.3s; 
      }
      .title3{
        transform: translateY(-20px);
      transition: all 0.3s; 

      }
      .word{
        transform: translateY(-20px);
      transition: all 0.3s; 

      }
      .add1,.word {
        display: block;
      }
      .add2 {
        display: none;
      }
      .img{
        height: 9.375rem;
      img{
        width: 100%;
        height: 100%;
      }
      }

    }
    .list_style {
      // width: 23%;
      width: 18.75rem;
      height: 22.4375rem;
      background: #ffffff;
      // margin: 0 1.625rem;
      // padding: 0 2rem;
      margin: 0 0.625rem;
    // padding: 0 1rem;
      box-sizing: border-box;
      position: relative;
      text-align: center;
      .line2 {
        width: 80%;
        height: .25rem;
        margin: 0 auto;
      }
      .serial_num {
        margin-top: 2.3125rem;
        font-size: 1.5rem;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #333333;
      }
      .title3 {
        height: 2.5625rem;
        font-size: 1.125rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
      }
      .word {
        font-size: .875rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        height: 3.125rem;
        line-height: 1.75rem;
        // margin-top: 1.4375rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2; // 想要超出三行显示 就把这里改成3就好了
     display: none;
      }
      .add1,
      .add2 {
        width: 1.625rem;
        height: 1.625rem;
        position: absolute;
        bottom: 1.3375rem;
        left: 50%;
        transform: translateX(-50%);
        z-index: 10;
      }
      .add1 {
        display: none;
      }
      .add2 {
        display: block;
      }
    }
  }
  .bom {
    width: 95%;
    height: 11.9375rem;
    background: #ffffff;
    box-shadow: 0px 0px 1.8125rem .1875rem rgba(0, 0, 0, 0.06);
    display: flex;
    margin: 0 auto;
    margin-top: 8.1875rem;
    .left {
      flex: 0.4;
      text-align: center;
      margin-top: 2.4375rem;
      font-size: 1.375rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
    }

    // 友情连接grid
    .wrapper {
      flex: 1.6;
      text-align: center;
      padding: 0 2rem;
      padding-top: 2.4375rem;
      display: grid;
      grid-template-columns: 25% 25% 25% 25%;
      .waf {
        // padding: 0 10px;
      }
    }
  }
}
.color1 {
  color: #356ceb !important;
}

</style>
