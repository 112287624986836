<template>
    <div class="web_x">

        <div class="left">
            <div class="grid-content bg-purple">
                <div class="title_color wow bounceInLeft">
                    <div class="title_b">关于我们</div>
                    <div class="title_m">/ ABOUT US</div>
                </div>
                <div class="col_about ">
                    <div class="img wow bounceInLeft"></div>
                    <div class="fsb_c">
                        <div class="col_about_title wow bounceInLeft">长三角（义乌）生态环境研究中心</div>

                        <div class="bomBtn wow bounceInLeft" @click="goCenter">
                            了解更多>
                        </div>

                    </div>
                    <div class="col_about_content wow bounceInLeft">依托国家级平台的科研力量和人才优势，围绕着生态文明建设、环境治理与生态保护、实现人与自然和谐发展的地方需求，服务地方生态环境可持续发展，为义乌、长三角区域乃至一带一路国家地区的生态环保行业发展提供全面的科技支撑和积极贡献
                        <!-- 依托中科院生态环境研究中心的科研力量和人才优势，围绕着生态文明建设、环境治理与生态保护、实现人与自然和谐发展的地方需求，服务义乌生态环境可持续发展，为义乌、长三角区域乃至一带一路国家地区的生态环保行业发展提供全面的科技支撑 -->
                    </div>
                </div>
            </div>
        </div>
        <div class="center">
            <div class="grid-content bg-purple-light">

                <div class="title_color wow bounceInRight">
                    <div class="title_b">新闻资讯</div>
                    <div class="title_m">/ NEW INFORMATION</div>
                </div>

                <div class="left wow lightSpeedIn">
                    <el-carousel height="22.1875rem" v-if="newsLists&&newsLists.length>0">
                        <el-carousel-item v-for="item in newsLists.slice(0,4)" :key="item.pid">
                            <div class="img" @click.stop="detail(item.pid)">
                                <img :src="item.pathUpload" alt="">
                            </div>
                            <div class="small" @click.stop="detail(item.pid)">{{ item.title }}</div>
                        </el-carousel-item>
                    </el-carousel>

                    <el-empty description="暂无数据" v-else></el-empty>
                </div>
            </div>
        </div>
        <div class="right">
            <div class="grid-content bg-purple">

                <div class="title_color wow bounceInRight">
                    <div class="title_b">{{''}}</div>
                    <div class="title_m">{{''}}</div>
                </div>
                <div class="right_top">
                    <div class="on">
                        <div :class="isActive?'active':''" @click="newsList('center')">中心动态</div>
                        <div :class="!isActive?'active':''" @click="newsList('news')">行业要闻</div>
                    </div>
                    <div class="btn_2" @click="goNewList({ pid: pid, href: '/news' })">
                        更多新闻+
                    </div>
                </div>
                <div class="right">
                    <vue-seamless-scroll :data="newsLists" :class-option="optionSingleHeightTime" class="seamless-warp">
                        <ul class="item">
                            <li v-for="(item, index) in newsLists" :key="index" @click.stop="detail(item.pid)">
                                <span class="title" v-text="item.title"></span>
                                <span class="date" v-text="item.submitTime?item.submitTime.slice(0, 10):''"></span>
                            </li>
                        </ul>
                    </vue-seamless-scroll>

                </div>
            </div>
        </div>
    </div>

</template>
<script>
export default {
    props: {
        list: {
            type: Array,
            default: () => [],
        },
        newsLists: {
            type: Array,
            default: () => [],
        },
        isActive: {
            type: Boolean
        }

    },
    data() {
        return {

        }
    },
    computed: {
        optionSingleHeightTime() {
            return {
                singleHeight: 50,
                waitTime: 2500
            }
        }

    },

    mounted() {
        new this.$wow.WOW().init()
    },
    methods: {
        goNewList(e) {
            this.$emit("goNewList", e);
        },
        detail(e) {
            console.log(e, "e");
            this.$emit("click", e);
        },
        newsList(type) {
            this.$emit("clickActive", type);

        },
        goCenter() {
            this.$emit("clickBtn");
        },
    },

}
</script>
<style lang="scss" scoped>
//   自定义样式
.title_color {
    height: 50px;
    min-height: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    border-bottom: .0625rem solid rgba(212, 223, 255, 1);
    margin: 15px 0;

    .title_b {
        height: 3.125rem;
        font-size: 26px;
        font-family: Microsoft YaHei;
        color: #000000;
        text-align: left;
        cursor: pointer;
        font-weight: bold;
        border-bottom: 3px solid #356ceb;
        margin-right: 10px;
    }

    .title_m {
        height: 2.4375rem;
        color: #000000;
        font-size: 1rem;
        border-bottom: 3px solid transparent;
    }
}

// 关于我们

.col_about {
    height: 22.1875rem;
    // background-color: #fff;

    .img {
        width: 100%;
        height: 13.5rem;
        background-image: url(../../assets/home/home_part1.jpg);
        background-repeat: no-repeat;
        background-size: cover;
    }

    &_title {
        padding: .9375rem 0;
        font-size: 1.375rem;
        font-weight: bold;
        color: #356ceb;
    }

    &_content {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        // height: 4.325rem;
        font-size: .875rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: 1.8;
    }

    .bomBtn {
        text-align: center;
        cursor: pointer;
        background: #E4EAF9;
        border-radius: 1.3125rem;
        font-size: .75rem;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #356CEB;
        border: .0625rem solid #356CEB;
        margin: .625rem 0;
        // padding: .3125rem .625rem;
        width: 5.4375rem;
        min-width: 70px;
        height: 1.5625rem;
        line-height: 1.5625rem;
    }
}
</style>

<style lang="scss" scoped>
.center {

    // 新闻资讯
    // left
    .left {
        width: 100%;
        height: 22.1875rem;

        .img {
            width: 100%;
            height: 22.1875rem;

            img {
                width: 100%;
                height: 100%;
            }

        }


        .small {
            width: 100%;
            height: 2.75rem;
            min-height: 2.75rem;
            overflow: hidden;
            line-height: 2.75rem;
            padding-left: .75rem;
            font-size: .875rem;
            position: absolute;
            color: #fff;
            bottom: 0;
            background-color: rgba(0, 12, 41, 0.2);
        }

    }
}

.right {

    // right
    .right {
        // height: calc(22.1875rem - 3.125rem);
        height: 18.5625rem;
        // background-color: #333;
        overflow: hidden;
        width: 100%;

        .item {
            li {
                cursor: pointer;
            }

            li:nth-child(2n) {

                padding: .625rem 0;
            }

            li:nth-child(2n+1) {
                padding: .625rem 0;
            }

            .title {
                color: #666666;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 1rem;
                display: inline-block;
                width: 19.5rem;
                // min-width: 300px;
                max-width: 600px;
            }

            .date {
                color: #666666;
                float: right;
                font-size: .875rem;
            }
        }
    }

    .right_top {
        display: flex;
        justify-content: space-between;
        color: #356CEB;
        height: 50px;
        min-height: 50px;
        line-height: 50px;
        margin: 10px 0;
        border-bottom: 1px solid #356CEB;
        font-size: 20px;

        .on {
            display: flex;
            font-size: 1rem;

            &>div {

                cursor: pointer;
                padding: 0 .3125rem;
                color: #333333;
                font-weight: 600;
            }

            div:first-child {
                margin-right: .625rem;

            }

            .active {
                color: #fff;
                background-color: #356CEB;
            }
        }


        .btn_2 {
            cursor: pointer;
            border-radius: 1.3125rem;
            text-align: center;
            font-size: .875rem;
            font-family: Microsoft YaHei;
            color: #333;
            padding: 0 .3125rem;
        }
    }
}

// 轮播 指示器
::v-deep {
    .el-carousel__indicators--horizontal {
        position: relative;
        left: auto;
        // right: -48%;
        bottom: 43.2px;
        text-align: right;
        transform: translateX(0);

        .el-carousel__indicator--horizontal button {
            width: 8px;
            height: 8px;
            background: #ffffff;
            border-radius: 50%;
            // opacity: 0.5;
        }

        .el-carousel__indicator--horizontal.is-active button {
            width: 24px;
            height: 8px;
            background: #ffffff;
            // opacity: 0.5;
            border-radius: 10px;
        }
    }



}
</style>
<style scoped lang="scss">
.web_x {
    width: 93.75rem;
    overflow: hidden;
    margin: auto;
    // padding: 1rem 3.125rem;
    padding-top: 6.1875rem;
}

.left,
.center,
.right {
    height: 100%;
    float: left;
    width: 32.8125rem;
    z-index: 9999;
}

.center {
    width: 29.9375rem;
    border-left: .875rem solid transparent;
    border-right: 1.0625rem solid transparent;
    box-sizing: border-box;
}

.right {
    width: 29.9375rem;
}

@media only screen and (max-width: 1200px) {
    .title_color {
        .title_b {
            font-size: 22px;
            height: 4.125rem;
        }

        .title_m {
            height: 3.1375rem;
        }
    }

    .web_x {
        width: 93.75rem;
        overflow: hidden;
        margin: auto;
    }

    .col_about_title {
        font-size: .9375rem;
    }

    .left {
        width: 50%;
        height: 100%;
    }

    .center {
        width: 50%;
        height: 100%;
        border-right: 0;
    }

    .right {
        width: 100%;
        height: 100%;

        .right {
            .item {
                .title {

                    width: 600px;
                }
            }
        }
    }

    // 关于我们
    .col_about {
        &_title {
            font-size: 18px;
        }

        .bomBtn {
            height: 20px;
            line-height: 20px;
        }
    }

}

@media only screen and (max-width: 980px) {

    // 关于我们
    .col_about {
        height: 100%;

        .img {
            height: 12.25rem;
        }

        &_title {}
    }

    .title_color {
        .title_b {
            font-size: 22px;
            height: 6.125rem;
        }

        .title_m {
            height: 4.8375rem;
        }
    }

    .web_x {
        width: 93%;
        overflow: hidden;
        margin: auto;
    }

    .right {
        margin-top: 10px;
        width: 100%;

        .right {
            border-top: 0;

            .item {
                .title {

                    width: 38.5rem;
                }
            }
        }
    }


    .center {
        width: 50%;
        border-right: hidden;

        .left {
            height: 22.4375rem;
        }
    }
}

@media only screen and (max-width: 640px) {

    // 关于我们
    .col_about {
        &_title {
            font-size: 2.9375rem;
        }
        .img {
            height: 32.25rem;
        }

        .bomBtn {
            height: 20px;
            line-height: 20px;
        }
    }

    .web_x {
        width: 93%;
        overflow: hidden;
    }

    .left,
    .center,
    .right {
        width: 100%;
        padding: 0 10px;
        display: block;
    }

    .left {}

    .title_color {
        .title_b {
            font-size: 22px;
            height: 8.125rem;
        }

        .title_m {
            height: 6.3375rem;
        }
    }

    .center {
        border: hidden;
        margin-top: 50px;

        .left {
            .img {
                width: 100%;
                // width: 28.6375rem;
                height: 355px;

                img {
                    width: 100%;
                    height: 100%;
                }

            }

            .small {
                // height: 104.4px;
                // line-height: 104.4px;
            }
        }
    }

    .right {
        .right {

            height: 355px;

            .right_top {
                height: 4rem;
                line-height: 4rem;
            }
        }
    }


}
</style>