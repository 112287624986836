<template>
  <div class="box">
    <div class="title  wow animate__backInDown">新闻资讯</div>
    <div class="title2  wow animate__backInDown">News information</div>
    <div class="line"></div>
    <div class="content">
      <div class="left wow lightSpeedIn">
        <el-carousel height="26.9rem">
          <el-carousel-item v-for="item in list.slice(0, 4)" :key="item.pid">
            <div class="img" @click.stop="detail(item.pid)">
              <img :src="item.pathUpload" alt="">
            </div>
            <div class="small" @click.stop="detail(item.pid)">{{ item.title }}</div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="right wow lightSpeedIn">
        <!-- <div class="right_top">
          <div class="on">在线动态</div>
          <div class="btn" @click="move({ pid: pid, href: '/news' })">
            更多新闻&nbsp;>
          </div>
        </div> -->
        <vue-seamless-scroll :data="list" :class-option="optionSingleHeightTime" class="seamless-warp">
          <ul class="item">
            <li v-for="(item, index) in list" :key="index" @click.stop="detail(item.pid)">
              <span class="title" v-text="item.title"></span><span class="date"
                v-text="item.submitTime.slice(0, 10)"></span>
            </li>
          </ul>
        </vue-seamless-scroll>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    pid: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      isActive: false,
      imgList: [],

    };
  },
  computed: {
    optionSingleHeightTime() {
      return {
        singleHeight: 52.5,
        waitTime: 2500
      }
    }
  },
  mounted(){
    new this.$wow.WOW().init()
  },
  methods: {
    move(e) {
      this.$emit("goNewList", e);
    },
    detail(e) {
      console.log(e, "e");
      this.$emit("detail", e);
    },
  },
};
</script>

<style lang="scss" scoped>
.flex {}

.box {
  width: 90%;

  .title {
    font-size: 2.25rem;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #356ceb;
    text-align: center;
    margin-top: 6rem;
    cursor: pointer;
  }

  .title2 {
    font-size: 1.5rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #5c5c5c;
    margin-top: 1.5rem;
    text-align: center;
  }

  .line {
    width: 1.875rem;
    height: .3125rem;
    background: #356ceb;
    margin: 0 auto;
    margin-top: 1.75rem;
    margin-bottom: 5.1875rem;
  }

  .content {
    width: 95%;
    height: 25rem;
    height: 100%;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;

    .left {
      width: 49%;
      padding-right: 5%;
      height: 25rem;

      img {
        width: 100%;
        height: 100%;
      }

      .img {
        height: 100%;
        position: relative;
      }

      .small {
        width: 100%;
        height: 3.125rem;
        line-height: 3.125rem;
        padding-left: 1.25rem;
        position: absolute;
        color: #fff;
        bottom: 0%;
        background-color: rgba(0, 12, 41, 0.2);
      }
    }

    .right {
      flex: 1;

      .item {
        li:nth-child(2n) {
          height: 3.125rem;
          line-height: 3.125rem;
          padding: 0 .625rem;
          background-color: #DCE3F3;
        }

        li:nth-child(2n+1) {
          height: 3.125rem;
          line-height: 3.125rem;
          padding: 0 .625rem;
          background-color: transparent;
        }

        .title {
          color: #666666;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: .75rem;
          // display: inline-block;
        }

        .date {
          float: right;
        }
      }

      .right_top {
        display: flex;
        justify-content: space-between;
        color: #356CEB;
        height: 1.875rem;
        line-height: 1.875rem;
        margin-bottom: 3.125rem;

        .on {
          font-weight: bold;
          font-size: 1.375rem;
          border-left: .5rem solid #356CEB;
          padding-left: .9375rem;
        }
      }
    }
  }

  .btn {
    cursor: pointer;
    width: 6.25rem;
    height: 2.625rem;
    border: .0625rem solid #3d73ff;
    border-radius: 1.3125rem;
    text-align: center;
    line-height: 2.625rem;
    font-size: .875rem;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #3d73ff;
  }
}

::v-deep .el-carousel__indicators--horizontal {
  position: absolute;
  left: auto;
  right: .625rem;
  bottom: .625rem;
  text-align: right;

  .el-carousel__indicator--horizontal button {
    width: .5rem;
    height: .5rem;
    background: #ffffff;
    border-radius: 50%;
    opacity: 0.5;
  }

  .el-carousel__indicator--horizontal.is-active button {
    width: 1.5rem;
    height: .5rem;
    background: #ffffff;
    opacity: 0.5;
    border-radius: .625rem;
  }
}

.seamless-warp {
  height: 21.9125rem;
  overflow: hidden;
}
</style>
