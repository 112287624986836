<template>
    <div class="box">
        <div v-if="false">

            <el-row>
                <el-col :span="9">
                    <div class="grid-content bg-purple">
                        <div class="title_color wow bounceInLeft">
                            <div class="title_b">关于我们</div>
                            <div class="title_m">/ ABOUT US</div>
                        </div>
                        <div class="col_about ">
                            <div class="img wow bounceInLeft"></div>
                            <div class="fsb_c">
                                <div class="col_about_title wow bounceInLeft">长三角（义乌）生态环境研究中心</div>

                                <div class="bomBtn wow bounceInLeft" @click="goCenter">
                                    了解更多>
                                </div>

                            </div>
                            <div class="col_about_content wow bounceInLeft">
                                依托国家级平台的科研力量和人才优势，围绕着生态文明建设、环境治理与生态保护、实现人与自然和谐发展的地方需求，服务地方生态环境可持续发展，为义乌、长三角区域乃至一带一路国家地区的生态环保行业发展提供全面的科技支撑和积极贡献
                                <!-- 依托中科院生态环境研究中心的科研力量和人才优势，围绕着生态文明建设、环境治理与生态保护、实现人与自然和谐发展的地方需求，服务义乌生态环境可持续发展，为义乌、长三角区域乃至一带一路国家地区的生态环保行业发展提供全面的科技支撑 -->
                            </div>

                        </div>
                    </div>
                </el-col>
                <el-col :span="7">
                    <div class="grid-content bg-purple-light">

                        <div class="title_color wow bounceInRight">
                            <div class="title_b">新闻资讯</div>
                            <div class="title_m">/ NEW INFORMATION</div>
                        </div>
                        <div class="left wow lightSpeedIn">
                            <el-carousel v-if="newsLists&&newsLists.length>0">
                                <el-carousel-item v-for="item in newsLists" :key="item.pid">
                                    <div class="img" @click.stop="move(item.pid)">
                                        <img :src="item.pathUpload" alt="">
                                    </div>
                                    <div class="small" @click.stop="move(item.pid)">{{ item.title }}</div>
                                </el-carousel-item>
                            </el-carousel>

                            <el-empty description="暂无数据" v-else></el-empty>
                        </div>
                    </div>
                </el-col>
                <el-col :span="8">
                    <div class="grid-content bg-purple">

                        <div class="title_color wow bounceInRight">
                            <div class="title_b">{{''}}</div>
                            <div class="title_m">{{''}}</div>
                        </div>
                        <div class="right_top">
                            <div class="on">
                                <div :class="isActive?'active':''" @click="newsList('center')">中心动态</div>
                                <div :class="!isActive?'active':''" @click="newsList('news')">行业要闻</div>
                            </div>
                            <div class="btn_2" @click="goNewList({ pid: pid, href: '/news' })">
                                更多新闻+
                            </div>
                        </div>
                        <div class="right">
                            <vue-seamless-scroll :data="newsLists" :class-option="optionSingleHeightTime"
                                class="seamless-warp">
                                <ul class="item">
                                    <li v-for="(item, index) in newsLists" :key="index" @click.stop="move(item.pid)">
                                        <span class="title" v-text="item.title"></span>
                                        <span class="date"
                                            v-text="item.submitTime?item.submitTime.slice(0, 10):''"></span>
                                    </li>
                                </ul>
                            </vue-seamless-scroll>

                        </div>
                    </div>
                </el-col>
            </el-row>

        </div>
        <div>
            <el-row class="wow bounceInLeft">

                <div class="title_color  wow bounceInLeft">
                    <div class="title_b">支撑与研发平台</div>
                    <div class="title_m">/ SUPPORT AND R & D PLATFORM</div>
                </div>
                <el-col :span="6" v-for="(item, index) in list" class="list_style wow animate__backInDown" :key="index">
                    <div class="grid-content bg-purple wow animate__backInDown" @click="move(item.pid)">
                        <div class="img">
                            <img :src="item.pathUpload" alt="">
                        </div>
                        <div style="height:calc(100% - 235px)">
                            <div class="serial_num">0{{ index + 1 }}</div>
                            <div class="title3">{{ item.title }}</div>
                            <div class="word">{{ item.other }}</div>
                            <div class="add1">
                                <img src="../../assets/home/add2.png" width="100%" height="100%" />
                            </div>
                            <div class="add2">
                                <img src="../../assets/home/add.png" width="100%" height="100%" />
                            </div>
                        </div>
                    </div>
                </el-col>
            </el-row>

        </div>
        <rotation-chart :list="xmList" @detail="move" ref="rotationChart" @engineeringTable="getProjectList"
            :projectList="projectList" @goDetails="goDetails"></rotation-chart>

    </div>
</template>
  
<script>
import rotationChart from "../components/rotationChart";
export default {
    props: {
        list: {
            type: Array,
            default: () => [],
        },
        newsLists: {
            type: Array,
            default: () => [],
        },
        projectList: {
            type: Array,
            default: () => [],
        },
        xmList: {
            type: Array,
            default: () => [],
        },

        isActive: {
            type: Boolean
        }


    },
    components: {
        rotationChart
    },
    data() {
        return {
            imgList: [],
            newspid: '',
            option: 0,
        };
    },
    computed: {
        optionSingleHeightTime() {
            return {
                singleHeight: 52.5,
                waitTime: 2500
            }
        }

    },
    mounted() {
        new this.$wow.WOW().init()
    },
    methods: {
        goDetails(e) {
            this.$emit("goDetails", { pid: e, option: 3 });
        },
        getProjectList(pid) {
            console.log(pid, 999);
            this.$emit("getProjectList", pid);
        },
        move(e) {
            this.$emit("click", { pid: e, option: 3 });
        },
        goCenter() {
            this.$emit("clickBtn");
        },
        goNewList(e) {
            this.$emit("goNewList", e);
        },
        newsList(type) {
            this.$emit("clickActive", type);
        },
    },
};
</script>
  
<style lang="scss" scoped>
.box {
    .box {
        width: 93.75rem;
        margin: auto;
        // padding: 7rem 3.125rem;
        // background-color: #f4f4f4;
        padding-top: 3rem;
    }
}

// el 布局
.el-row {
    margin-bottom: 1.25rem;

    &:last-child {
        margin-bottom: 0;
    }
}

.el-row .el-col~.el-col {
    margin-left: 1.875rem;
}

.el-col {
    border-radius: .25rem;
    padding: .3125rem 0;

}

.bg-purple {

    height: 22rem;

}

.grid-content {
    border-radius: .25rem;
    height: 22rem;
    min-height: 2.25rem;
    // box-shadow: 0 0 10px 2px #f4f4f4;
}


//   自定义样式
.title_color {
    height: 3.125rem;
    min-height: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    border-bottom: .0625rem solid rgba(212, 223, 255, 1);
    margin: 15px 0;

    .title_b {
        height: 3.125rem;
        font-size: 26px;
        font-family: Microsoft YaHei;
        color: #000000;
        text-align: left;
        cursor: pointer;
        font-weight: bold;
        border-bottom: 3px solid #356ceb;
        margin-right: 10px;
    }

    .title_m {
        height: 2.4375rem;
        color: #000000;
        font-size: 1rem;
        border-bottom: 3px solid transparent;
    }
}




// 支撑与研发平台
// .content {

.list_style:hover {
    .grid-content {
        box-shadow: 0px 0px .9375rem 1px rgba(34, 39, 46, 0.3);
        transform: translateY(-1.25rem);
        transition: all 0.3s;

    }

    .line2 {
        background: #356ceb;
    }

    .serial_num {
        color: #356ceb;
        transform: translateY(-0.625rem);
        transition: all 0.3s;
    }

    .title3 {
        transform: translateY(-0.625rem);
        transition: all 0.3s;
        font-family: Microsoft YaHei;
        color: #333333;
        font-weight: bolder;
        font-size: 1.125rem;

    }

    .word {
        transform: translateY(-20px);
        transition: all 0.3s;
        margin: 0 1.25rem;
    }

    .add1,
    .word {
        display: block;
    }

    .add2 {
        display: none;
    }

    .img {
        height: 11.375rem;

        img {
            width: 100%;
            height: 100%;
        }
    }

}

.list_style {
    box-sizing: border-box;
    position: relative;
    text-align: center;
    cursor: pointer;
    width: 22rem;
    // height: 24.4375rem;
    
    height: 100%;


    .bg-purple {
        height: 24rem;
        position: relative;
        background-color: #ffffff;
    }

    .line2 {
        width: 80%;
        height: .25rem;
        margin: 0 auto;
    }

    .serial_num {
        padding: .9375rem 0;
        font-size: 1.5rem;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #333333;
    }

    .title3 {
        height: 2.5625rem;
        font-size: 1.125rem;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .img {
        width: 100%;
        height: 14.6875rem;
    }

    img {
        width: 100%;
        height: 100%;
    }


    .word {
        font-size: .875rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        height: 3.125rem;
        line-height: 1.75rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2; // 想要超出三行显示 就把这里改成3就好了
        display: none;
    }

    .add1,
    .add2 {
        width: 1.5625rem;
        height: 1.5625rem;
        position: absolute;
        bottom: 0.6375rem;
        left: 50%;
        transform: translateX(-50%);
        z-index: 10;
    }

    .add1 {
        display: none;
    }

    .add2 {
        display: block;
    }
}

//   }
// 轮播 指示器
::v-deep {
    .el-carousel__indicators--horizontal {
        position: relative;
        left: auto;
        // right: -48%;
        bottom: 5.2rem;
        text-align: right;
        transform: translateX(0);

        .el-carousel__indicator--horizontal button {
            width: .5rem;
            height: .5rem;
            background: #ffffff;
            border-radius: 50%;
            // opacity: 0.5;
        }

        .el-carousel__indicator--horizontal.is-active button {
            width: 1.5rem;
            height: .5rem;
            background: #ffffff;
            // opacity: 0.5;
            border-radius: .625rem;
        }
    }

    // .el-carousel.el-carousel--horizontal {
    //     height: 22rem;
    // }

    .el-carousel__container {
        height: 22rem !important;
    }
}
</style>
<style lang="scss" scoped>
// 新闻资讯
// left
.left {


    img {
        width: 100%;
        height: 100%;
    }

    .img {
        height: 30rem;
    }

    .small {
        width: 100%;
        height: 3.125rem;
        overflow: hidden;
        line-height: 3.125rem;
        padding-left: 1.25rem;
        position: absolute;
        color: #fff;
        bottom: 0;
        background-color: rgba(0, 12, 41, 0.2);
    }

}

// right
.right {
    height: 18.75rem;
    overflow: hidden;

    .item {
        li:nth-child(2n) {
            height: 3.125rem;
            line-height: 3.125rem;
            padding: 0 .625rem;
            // background-color: #DCE3F3;
        }

        li:nth-child(2n+1) {
            height: 3.125rem;
            line-height: 3.125rem;
            padding: 0 .625rem;
            // background-color: transparent;
        }

        .title {
            color: #666666;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: .75rem;
            display: inline-block;
            width: 18.5rem;
        }

        .date {
            float: right;
        }
    }
}

.right_top {
    display: flex;
    justify-content: space-between;
    color: #356CEB;
    height: 1.875rem;
    line-height: 1.875rem;
    // margin-bottom: 3.125rem;
    margin: .625rem 0;
    border-bottom: 1px solid #356CEB;

    .on {
        display: flex;

        &>div {

            cursor: pointer;
            padding: 0 .3125rem;
            color: #333333;
        }

        div:first-child {
            margin-right: .625rem;

        }

        .active {
            color: #fff;
            background-color: #356CEB;
        }
    }


    .btn_2 {
        cursor: pointer;
        border-radius: 1.3125rem;
        text-align: center;
        font-size: .875rem;
        font-family: Microsoft YaHei;
        color: #333;
        padding: 0 .3125rem;
    }
}
</style>

<style lang="scss" scoped>
@media only screen and (max-width: 1200px) {
    .title_color {
        .title_b {
            font-size: 22px;
            height: 4.125rem;
        }
        .title_m {
            height: 3.1375rem;
        }
    }
}

@media only screen and (max-width: 980px) {
    .project_part_top {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .title_color {
        .title_b {
            font-size: 22px;
            height: 6.125rem;
        }
        .title_m {
            height: 4.8375rem;
        }
    }
    .list_style {
        // padding: 0 1rem;
        box-sizing: border-box;
        position: relative;
        text-align: center;
        cursor: pointer;
        height: 100%;

        .bg-purple {
            height: 29rem;
            min-height: 150px;
            // box-shadow: 0 0 20px 1px grey;
            // margin: 0 .625rem;
            position: relative;
            background-color: #ffffff;
        }

        .line2 {
            width: 80%;
            height: .25rem;
            margin: 0 auto;
        }

        .serial_num {
            margin-top: 2.3125rem;
            font-size: 1.5rem;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #333333;
        }

        .title3 {
            min-height: 4.5625rem;
            font-size: 1.125rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        img {
            width: 100%;
            height: 100%;
        }


        .word {
            font-size: .875rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #666666;
            height: 4.125rem;
            line-height: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1; // 想要超出三行显示 就把这里改成3就好了
            display: none;
        }

        .add1,
        .add2 {
            width: 16px;
            height: 16px;
            position: absolute;
            bottom: 1.3375rem;
            left: 50%;
            transform: translateX(-50%);
            z-index: 10;
        }

        .add1 {
            display: none;
        }

        .add2 {
            display: block;
        }
    }

}

@media only screen and (max-width: 640px) {
    .list_style:hover {
        .grid-content {
            box-shadow: 0px 0px .9375rem 1px rgba(34, 39, 46, 0.3);
            transform: translateY(-1.25rem);
            transition: all 0.3s;

        }

        .line2 {
            background: #356ceb;
        }

        .serial_num {
            color: #356ceb;
            transform: translateY(-0.625rem);
            transition: all 0.3s;
        }

        .title3 {
            transform: translateY(-0.625rem);
            transition: all 0.3s;height: 4.5625rem;

        }

        .word {
            transform: translateY(-1.25rem);
            transition: all 0.3s;height: 3.525rem;

        }

        .add1,
        .word {
            display: block;
        }

        .add2 {
            display: none;
        }

        .img {
            height: 8.375rem;

            img {
                width: 100%;
                height: 100%;
            }
        }

    }

    .list_style {
        // padding: 0 1rem;
        box-sizing: border-box;
        position: relative;
        text-align: center;
        cursor: pointer;

        .bg-purple {
            height: 22rem;
            min-height: 150px;
            // box-shadow: 0 0 20px 1px grey;
            // margin: 0 .625rem;
            position: relative;
            background-color: #ffffff;
        }

        .line2 {
            width: 80%;
            height: .25rem;
            margin: 0 auto;
        }

        .serial_num {
            margin-top: 2.3125rem;
            font-size: 1.5rem;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #333333;
        }

        .title3 {
            min-height: 4.5625rem;
            font-size: 1.125rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        img {
            width: 100%;
            height: 100%;
        }


        .word {
            font-size: .875rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #666666;
            height: 4.125rem;
            line-height: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1; // 想要超出三行显示 就把这里改成3就好了
            display: none;
        }

        .add1,
        .add2 {
            width: 16px;
            height: 16px;
            position: absolute;
            bottom: 1.3375rem;
            left: 50%;
            transform: translateX(-50%);
            z-index: 10;
        }

        .add1 {
            display: none;
        }

        .add2 {
            display: block;
        }
    }
    .title_color {
        .title_b {
            font-size: 22px;
            height: 8.125rem;
        }
        .title_m {
            height: 6.3375rem;
        }
    }


}
</style>