<template>
  <!-- 项目成果2.0 -->
  <div class="project_part">
    <div class="project_part_top">
      <div class="title_color  wow bounceInLeft">
        <div class="title_b  wow bounceInLeft">工程项目成果</div>
        <div class="title_m  wow bounceInLeft">/ PROJECT CHIEVEMENTS</div>
      </div>
      <div>
        <div class="list_child wow bounceInRight">
          <div :class="[childActive==index?'child_active':'','list_child_item','SemiBold']" v-for="(item,index) in list"
            :key="index" @click="engineeringTable(index,item)">
            {{item.typename}}
          </div>
        </div>
      </div>

    </div>
    <div class="home wow bounceInRight">
      <el-row :gutter="20" type="flex" justify="center">
        <el-col :span="20">

          <vue-seamless-scroll :data="projectList" :class-option="classOption" class="warp">
            <ul class="ul-item">
              <li class="li-item" v-for="(item, index) in projectList" :key="index" @click.stop="detail(item.pid)">
                <div>
                  <div class="li-item_img"><img :src="item.pathUpload" alt=""></div>
                  <div class="li-item_title">{{item.title}}</div>

                </div>
              </li>
            </ul>
          </vue-seamless-scroll>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
// import swiperVue from "../../components/swiper/swiper";
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    projectList: {
      type: Array,
      default: () => [],
    },

  },
  components: {
    // swiperVue
  },
  data() {
    return {
      width: "",
      fatherWith: 0,
      box: '',// dom
      childActive: 0,
      // listData: [1, 2, 3, 4, 5, 6],
      classOption: {
        limitMoveNum: 2,
        switchSingleStep: 228,
        direction: 2,
        openTouch: true,
        singleWidth: 228,
        waitTime: 2000,
        // navigation:true
      }
    };
  },
  mounted() {

  },
  methods: {
    engineeringTable(index, item) {
      this.childActive = index
      console.log(item, 'pid');
      this.$emit('engineeringTable', item.pid)
    },
    detail(e) {
      console.log(e, "e");
      this.$emit("detail", e);
    },
  },
};
</script>

<style lang="scss" scoped>
.warp {
  width: 100%;
  height: 29rem;
  overflow: hidden;


  ul {
    list-style: none;
    padding: 0;
    margin: 0 auto;

    &.ul-item {
      display: flex;

      // & .li-item~.li-item {
      //   margin-left: 1.875rem;

      // }

      .li-item {
        margin-left: 1.875rem;
        width: 22rem;
        height: 26.5rem;
        color: #fff;
        text-align: center;
        font-size: 1.875rem;
        cursor: pointer;

        .li-item_img {
          width: 22rem;
          height: 26.5rem;

          img {
            width: 100%;
            height: 100%;

          }
        }

        .li-item_title {
          height: 3.1875rem;
          line-height: 3.1875rem;
          position: relative;
          background-color: rgba(2, 12, 33, 0.2);
          bottom: 3.1875rem;
          font-size: 1.125rem;
          font-weight: bold;
          display: none;
        }
      }

      .li-item:hover .li-item_title {
        display: block;
      }

      // .li-item:hover {
      //   transform:scale(1.1);
      //   transition: all 2s;
      // }
    }
  }
}

// new style
.project_part_top {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: .0625rem solid rgba(212, 223, 255, 1);
  padding-top: 4.75rem;

  // 
  .title_color {
    height: 3.125rem;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;

    .title_b {
      height: 3.125rem;
      font-size: 26px;
      font-family: Microsoft YaHei;
      color: #000000;
      text-align: left;
      cursor: pointer;
      font-weight: bold;
      border-bottom: 3px solid #356ceb;
      margin-right: .625rem;
    }

    .title_m {
      height: 2.4375rem;
      color: #000000;
      font-size: 1rem;
      border-bottom: 3px solid transparent;
    }
  }

  .list_child {
    // min-width: 100px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .list_child_item {
      letter-spacing: 2px;
      // word-spacing:2px;
      width: 114px;
      height: 34px;
      line-height: 34px;
      min-width: 80px;
      background-color: rgba(53, 108, 235, 0.25);
      text-align: center;
      color: #356CEB;
      margin: 10px 5px;
      cursor: pointer;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-family: AlibabaPuHuiTi-2-75-SemiBold;
      // font-family: AlibabaPuHuiTi-2-65-Medium;
      // font-family: AlibabaPuHuiTi-2-85-Bold;
      font-size: 16px;

    }

    .list_child_item:hover {
      background-color: #1157d0;
      color: #fff;
    }

    .child_active {
      background-color: #1157d0;
      color: #fff;
    }
  }
}

.home {
  margin-top: 50px;
  // width: 1000px;
  // overflow: scroll;

  // width: 1400px;
  // background-color: #fff;

  .home_part_f {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .swiper-box {
    display: flex;
    width: auto;
    transition: all .2s;
  }
}

[class ^="el-icon-arrow-"] {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.875rem;
  padding: 10px;
  background-color: #FFF;
  border-radius: 100%;
  cursor: pointer;
  opacity: 0.5;
}




.project_part {
  width: 100%;

  .title {
    font-size: 2.25rem;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffffff;
    text-align: center;
    margin-top: 5.375rem;
  }

  .title2 {
    font-size: 1.5rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    text-align: center;
    color: rgba($color: #ffffff, $alpha: 0.4);
    margin-top: 1.5rem;
    margin-bottom: 5.75rem;
  }

  .content {
    .el-carousel__item .card_box {
      color: #475669;
      font-size: .875rem;
      opacity: 0.75;
      // line-height: 12.5rem;
      margin: 0;
      height: 20.5rem;
      width: 34.375rem;
      background: #ffffff;
      margin: 0 auto;
      display: flex;

      .left {
        width: 26.875rem;
        height: 20.5rem;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .right {
        width: 19.75rem;

        .title3 {
          font-size: 1.175rem;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #222222;
          margin: 1.875rem .525rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .title4 {
          font-size: .875rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #6f7685;
          // height: 6.9125rem;
          line-height: 2;
          margin: 0 .525rem;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 6; // 想要超出三行显示 就把这里改成3就好了
        }

        .btn {
          cursor: pointer;
          width: 12.5rem;
          height: 1.75rem;
          background: #356ceb;
          border-radius: .125rem;
          border: none;
          font-size: .125rem;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #f0f3f6;
          margin: 0 3.525rem;
          margin-top: 1.525rem;
        }
      }
    }
  }
}

.content ::v-deep .el-carousel__mask {
  opacity: 1 !important;
  background: none !important;
}

.el-carousel.el-carousel--horizontal.el-carousel--card {
  width: 100vw;
  margin: auto;
}

.el-col-20 {
  width: 100%;
  padding: 0;
}
</style>

<style lang="scss" scoped>
@media only screen and (max-width: 1200px) {
  .project_part_top {
    .title_color {
      .title_b {
        font-size: 22px;
        height: 4.125rem;
      }

      .title_m {
        height: 3.1375rem;
      }
    }
  }

}

@media only screen and (max-width: 980px) {
  .project_part_top {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .title_color {
      .title_b {
        font-size: 22px;
        height: 6.125rem;
      }

      .title_m {
        height: 4.8375rem;
      }
    }
  }


}

@media only screen and (max-width: 640px) {
  .project_part_top {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 50px;

    .title_color {
      .title_b {
        font-size: 22px;
        height: 8.125rem;
      }

      .title_m {
        height: 6.3375rem;
      }
    }
  }


}
</style>