<template>
  <div class="box">
    <div class="top">
      <top-nav></top-nav>
    </div>
    <div class="carousel">
      <el-carousel :interval="3000" :autoplay="true" height="75vh">
        <el-carousel-item v-for="(item, index) in bannerList" :key="index">
          <div class="nav_top" :style="{ backgroundImage: 'url('+ item.img +')',backgroundSize:'cover' }">
            <!-- <div class="nav_top" :style="{ 'background': `url(${item.img}) no-repeat 100% 100%` }"> -->
            <div class="text YanShiYouRanXiaoKai">
              <div class="first">水·环境·生生不息</div>
              <div>立足义乌 服务长三角 面向全国 辐射一带一路</div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 聚在一起 -->
    <div class="part_all_bg">
      <demo :isActive="isActive" :newsLists="newsLists" @clickActive="clickActive" @click="goDetails"
        @goNewList="goNewList" @clickBtn="goCenter"></demo>

      <home :isActive="isActive" :list="zcList" @clickActive="clickActive" :newsLists="newsLists" @click="goDetails"
        @goNewList="goNewList" @clickBtn="goCenter" :xmList="xmList" @getProjectList="getProjectList"
        :projectList="projectList"></home>
    </div>
    <div class="content1 wow fadeIn" v-if="false">

      <div class="title wow animate__backInDown" data-wow-delay="0.08s">关于我们</div>
      <div class="title2">About us</div>
      <div class="line"></div>
      <div class="content1_content">

        <div class="left wow bounceInLeft">
          <div class="title">长三角（义乌）生态环境研究中心</div>
          <div class="title2">Yangtze River Delta Research Center for Eco-Environmental Sciences，Yiwu
          </div>
          <!-- <div class="title2">
          围绕地方及行业需求，开展生态环境、环保技术等领域的全方位合作
        </div> -->
          <div class="line"></div>
          <div class="word">
            长三角（义乌）生态环境研究中心由中国科学院生态环境研究中心与义乌市人民政府共同举办，中心于2019年6月在义乌成立。中心围绕地方及行业需求，以生态环境、环保技术等领域的产学研用合作为契机，服务义乌生态环境可持续发展，为义乌、长三角区域乃至“一带一路”国家地区的生态环保行业发展提供全面的科技支撑，推动长三角生态环境领域标准化制定、环境治理、认证及服务等工作，成为国内特色鲜明的集技术创新、产业化和人才培养的基地
          </div>
          <div class="bomBtn" @click="goCenter">
            了解更多 &nbsp; &nbsp; &nbsp;>
          </div>
        </div>
        <div class="right wow bounceInRight">
          <!-- <div class="imgs1">
          <img src="../assets/home/img2.png" />
        </div> -->
          <div class="imgs2">
            <img src="../assets/home/img1.png" />
          </div>
        </div>
      </div>
    </div>
    <div class="content2" v-if="false">
      <rotation-chart :list="xmList" @goDetails="goDetails"></rotation-chart>
    </div>
    <div class="content3" v-if="false">
      <news-info :list="newsList" :pid="newspid" @goNewList="goNewList" @detail="goDetails"></news-info>
    </div>
    <div class="content4" v-if="false">
      <div style="width: 80%;margin:auto;">
        <brace-research :list="zcList" :linkList="linkList" @click="goDetails"></brace-research>
      </div>
    </div>
    <div class="content5">
      <bottom></bottom>
    </div>
  </div>
</template>

<script>
import topNav from "../components/topNav";
import rotationChart from "./components/rotationChart";
import newsInfo from "./components/newsInfo";
import braceResearch from "./components/braceResearch";
import home from "./components/home";
import demo from "./components/demo";
import bottom from "../components/bottom";
import { province, statistics, menuList, listPain } from "../api/api";

export default {
  components: {
    topNav,
    rotationChart,
    newsInfo,
    braceResearch,
    bottom,
    home, demo
  },
  data() {
    return {
      userInfo: "",
      obj: {
        supplierNum: "",
        userNum: "",
        orderNum: "",
        amountSum: "",
      },
      navBomList: [
        {
          title: "注册供应商数量",
          num: "supplierNum",
        },
        {
          title: "注册用户数量",
          num: "userNum",
        },
        {
          title: "成交订单数量",
          num: "orderNum",
        },
        {
          title: "成交订单总金额(元）",
          num: "amountSum",
        },
      ],
      ywList: [],
      xmList: [],
      projectList: [],//工程项目
      zcList: [],
      newsList: [],
      newsTopList: [],//新闻二级
      newsLists: [],//新闻列表
      linkList: [],
      isActive: true,
      linkpid: "",
      ywpid: "",
      xmpid: "",
      zcpid: "",
      newspid: "",
      pidDetail: "",
      bannerList: [
        {
          img: require('../assets/home/banner.png')
        },
        {
          img: require('../assets/home/banner2.png')
        },
      ],
    };
  },
  beforeMount() {
    let _province = localStorage.getItem("province");
    if (!_province) {
      this.getprovince();
    }
  },
  mounted() {
    this.getinto();
    this.menuList();
    new this.$wow.WOW().init();

  },
  methods: {
    //获取省市
    async getprovince() {
      let res = await province();
      if (res.ResultCode == 0) {
        // console.log(res, "res");
        localStorage.setItem("province", JSON.stringify(res.Data));
      }
    },
    async getinto() {
      let res = await statistics();
      if (res.ResultCode == 0) {
        this.obj = res.Data;
        // console.log(res, "res");
      }
    },
    clickActive(type) {
      if (type == 'center') {
        this.newspid = this.newsTopList[0].pid
        this.isActive = true
      }
      if (type == 'news') {
        this.newspid = this.newsTopList[1].pid
        this.isActive = false
      }
      this.newsTopLists()
    },
    // 获取新闻列表
    async newsTopLists() {
      // 新闻
      if (this.newspid) {
        let listPains = await listPain({ typeidSelsource: this.newspid });
        console.log(listPains, 'reslistnewsPain')
        if (listPains.ResultCode == 0) {
          this.newsLists = listPains.Data.Items.map(i => {
            let obj = {
              title: '',
              pid: '',
              submitTime: '',
              pathUpload: ''
            }
            obj['title'] = i.title
            obj['pid'] = i.pid
            obj['submitTime'] = i.submitTime
            obj['pathUpload'] = i.pathUpload
            return obj
          });
          console.log(this.newsLists, 'this.newsList');
        }
      }
    },

    async menuList() {
      let res = await menuList();
      if (res.ResultCode == 0) {
        this.linkpid = res.Data[0].childs[0].pid;
        this.ywpid = res.Data[0].childs[1].pid;
        this.xmpid = res.Data[0].childs[2].pid;
        this.zcpid = res.Data[0].childs[3].pid;
        this.newspid = res.Data[2].pid;
        this.newsTopList = res.Data[2].childs;
        // /项目成果三级xmList

        this.xmList = res.Data[3].childs[0].childs;
      }
      // 工程项目列表初始化
      if (this.xmList && this.xmList.length > 0) {
        this.getProjectList(this.xmList[0].pid)
      }
      // 新闻列表初始化
      if (this.newsTopList && this.newsTopList.length > 0) {
        this.newspid = this.newsTopList[0].pid
        this.newsTopLists()
      }
      console.log(this.newspid);
      // 友情链接
      if (this.linkpid) {
        let linkPains = await listPain({ typeidSelsource: this.linkpid });
        if (linkPains.ResultCode == 0) {
          let obj = JSON.parse(linkPains.Data.Items[0].other);
          this.linkList = obj;
          // console.log(this.linkList,'有脸');
          // }
        }
      }
      // 义务长三角中心
      if (this.ywpid) {
        let listPains = await listPain({ typeidSelsource: this.ywpid });
        // console.log(listPains, "reslistPain");
        if (listPains.ResultCode == 0) {
          this.ywList = listPains.Data.Items;
          this.pidDetail = listPains.Data.Items[0].pid;
        }
      }
      // 支撑与研发平台
      if (this.zcpid) {
        let listPains = await listPain({ typeidSelsource: this.zcpid });
        // console.log(listPains,'reslistPain')
        if (listPains.ResultCode == 0) {
          this.zcList = listPains.Data.Items.slice(0, 4);
          console.log(this.zcList);
        }
      }
      // 新闻
      if (this.newspid) {
        let listPains = await listPain({ typeidSelsource: this.newspid });
        console.log(listPains, 'reslistnewsPain')
        if (listPains.ResultCode == 0) {
          this.newsList = listPains.Data.Items.map(i => {
            let obj = {
              title: '',
              pid: '',
              submitTime: '',
              pathUpload: ''
            }
            obj['title'] = i.title
            obj['pid'] = i.pid
            obj['submitTime'] = i.submitTime
            obj['pathUpload'] = i.pathUpload
            return obj
          });
          console.log(this.newsList, 'this.newsList');
        }
      }
    },
    // 工程项目列表
    async getProjectList(pid) {
      let listPains = await listPain({ typeidSelsource: pid });
      // console.log(listPains,'xmreslistPain')
      if (listPains.ResultCode == 0) {
        this.projectList = listPains.Data.Items;
      }
    },
    goDetails(e) {
      // console.log(e, "go");
      let goPid = e.pid || e;
      // option 3是不显示时间 没有就是显示
      this.$router.push({
        path: "/listDetails",
        query: {
          pid: goPid,
          type: (e.option && e.option == 3) ? 0 : 1,
          isTitle: 1
        },
      });
    },
    goNewList(e) {
      this.$router.push({
        path: e.href,
        query: {
          pid: e.pid,
        },
      });
    },
    goCenter() {
      this.$router.push({
        path: '/centerIntroduced',
      });
    },

  },
};
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  // min-width: 1000px;
  box-sizing: border-box;

  .part_all_bg {
    background-image: url("../assets/home/bg .png");
    background-repeat: no-repeat;
    background-size: 100% 100%;

  }

  .carousel {
    // width: 100vw;
    background-color: #076ce0;
  }

  .nav_top {
    width: 100vw;
    height: 75vh;
    // height: 740px;
    // max-height: 662px;
    // background-image: url("../assets/home/banner.png");
    // background-repeat: no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;

    .top {
      width: 100%;
      height: 6.25rem;
      background-size: 100% 6.25rem;
      background-repeat: no-repeat;
    }

    .text {
      height: 100%;
      // min-height: 662px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: #ffffff;
      text-align: center;
      font-size: 3.125rem;
      // font-family: 'YanShiYouRanXiaoKai';

      position: relative;
      animation-name: example;
      animation-duration: 4s;
      animation-iteration-count: 1;
      animation-direction: alternate;

      .first {
        font-size: 6.875rem;
        font-weight: bold;
        margin-bottom: 3.125rem;
      }
    }
  }

  .content1 {
    width: 100%;
    height: 57.5625rem;
    margin: 0 auto;
    background-color: #ffffff;
    // padding-top: 10.75rem;
    overflow: hidden;
    background-image: url("../assets/home/bg_conter1.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;

    .title {
      font-size: 2.25rem;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #356ceb;
      text-align: center;
      margin-top: 6rem;
      cursor: pointer;
    }

    .title2 {
      font-size: 1.5rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #5c5c5c;
      margin-top: 1.5rem;
      text-align: center;
    }

    .line {
      width: 1.875rem;
      height: .3125rem;
      background: #356ceb;
      margin: 0 auto;
      margin-top: 1.75rem;
      // margin-bottom: 5.1875rem;
    }

    .content1_content {
      width: 80%;
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      margin-top: 6rem;

      .left {
        text-align: left;
        height: 424px;

        .title {
          font-size: 2rem;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #356ceb;
          text-align: left;
          margin-top: 0;
        }

        .title2 {
          // width: 30rem;
          font-size: 1rem;
          font-family: PingFang SC;
          font-weight: 500;
          color: #333333;
          margin-top: 1.1875rem;
          margin-bottom: 1.5625rem;
          text-align: left;
        }

        .line {
          width: 1.875rem;
          height: .3125rem;
          background: #076ce0;
          margin-left: 0;
        }

        .word {
          // width: 29.05rem;
          width: 36.05rem;
          font-size: 1rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          line-height: 1.625rem;
          margin-top: 3.5625rem;
        }

        .bomBtn {
          cursor: pointer;
          width: 8.125rem;
          height: 2.5rem;
          background: #356ceb;
          border-radius: 21px 21px 21px 21px;
          text-align: center;
          line-height: 2.5rem;
          font-size: .875rem;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #ffffff;
          margin-top: 2.125rem;
        }
      }

      .right {

        width: 43.25rem;
        height: 424px;
        text-align: right;

        .imgs2 {
          // width: 100%;
          height: 100%;
          margin: auto;
          margin-left: 3.125rem;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .imgs2:hover {
          transform: translateY(-20px);
          transition: all 0.3s;
        }
      }
    }
  }

  .content2 {
    width: 100%;
    height: 48.375rem;
    overflow: hidden;
  }

  .content3 {
    width: 100%;
    height: 58rem;
    max-width: 1400px;
    margin: auto;
    overflow: hidden;
    background-image: url("../assets/home/bg_conter3.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .content4 {
    height: 51.3125rem;
    background: #f5f5f5;
    background-image: url("../assets/home/bg_conter4.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .content5 {
    width: 100%;
    // height: 19.375rem;
    background: #f5f5f5;
    overflow: hidden;
  }
}

.a_style {
  border-right: .0625rem solid rgba($color: #ffffff, $alpha: 0.4) !important;
}

::v-deep .el-carousel__indicators--horizontal {

  .el-carousel__indicator--horizontal button {
    width: 10px;
    height: 10px;
    background: transparent;
    // background-image: url('../assets/dian2.png');
    // background-repeat: no-repeat;
    // background-size: 100% 100%;
    background-color: #fff;
    opacity: 0.6;
    border-radius: 50%;
    bottom: 10px;
    margin-right: 10px;
  }

  .el-carousel__indicator--horizontal.is-active button {
    width: 10px;
    height: 10px;
    background: transparent;
    background: #3E78FF;
    // background-image: url('../assets/dian.png');
    // background-repeat: no-repeat;
    // background-size: 100% 100%;
    opacity: 1;
    // opacity: 0.5;
    // border-radius: .625rem;
  }
}
</style>
